import domReady from '@roots/sage/client/dom-ready';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

/**
 *open filter content
 */
jQuery(document).ready(function ($) {
  AOS.init();

  /**
   *
   * Search
   */

  $('.search_container_toggle_mobile').on('click', function () {
    $('#search_content').toggleClass('active');
  });
  $('.search_container_toggle').on('click', function () {
    $('#search_content').toggleClass('active');
    $('body').toggleClass('primary_menu_active');
  });

  $('#search_btn').on('click', function () {
    search();
  });

  $('#s').on('keyup', function (event) {
    if (event.key === 'Enter') {
      search();
    }
  });

  function search() {
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'get',
      data: {
        action: 'load_search',
        s: $('#s').val(),
      },
      success: function (response) {
        $('#search_result').html('');
        response.view.forEach(function (post) {
          $('#search_result').append(post);
        });
      },
      error: function (error) {
        console.error('Erro ao carregar posts:', error);
      },
    });
  }
  /**
   *
   *
   */

  $('.toggle_filtro').on('click', function () {
    $('#page-filter').toggleClass('active');
    $('.filtroAddActive').toggleClass('active');
  });
  $('.clear_filter').on('click', function () {
    load_projeto_ajax({
      paged: 1,
    });

    $('ul.filter_category li').removeClass('active');

    $('#filter_sub_category ul li').removeClass('parent_active');
    $('#filter_sub_category ul li').removeClass('active');
  });

  $('ul.filter_category li').on('click', function () {
    $(this).siblings().removeClass('active');
    $(this).addClass('active');

    const id = $(this).data('id');
    load_projeto_ajax({
      paged: 1,
      category: id,
    });

    if ($('#filter_sub_category ul li.' + id).length > 0) {
      $('#filter_sub_category').addClass('active');
      $('#filter_sub_category ul li.' + id)
        .siblings()
        .removeClass('parent_active');
      $('#filter_sub_category ul li.' + id).addClass('parent_active');
    } else {
      $('#filter_sub_category').removeClass('active');
      $('#filter_sub_category ul li').removeClass('parent_active');
    }
  });

  $('#filter_sub_category ul li').on('click', function () {
    $(this).siblings().removeClass('active');
    $(this).addClass('active');
    const id = $(this).data('id');
    const parent = $(this).data('parent');
    load_projeto_ajax({
      paged: 1,
      category: parent,
      sub_category: id,
    });
  });

  /* header visiel on scroll  */

  const header = document.getElementById('header');
  var lastScrollTop = 0;
  window.addEventListener('scroll', function () {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st < lastScrollTop && st > 130) {
      header.classList.add('active_scrool');
    } else {
      header.classList.remove('active_scrool');
    }
    lastScrollTop = st <= 0 ? 0 : st;
  });

  /**
   *
   *  header Menu primary_navigation
   */

  $('#primary_navigation').on('click', 'a', function () {
    $('#primary_menu').removeClass('active');
    $('body').removeClass('primary_menu_active');
  });

  $('.icon_menu').on('click', function () {
    $('#primary_menu').addClass('active');
    $('body').addClass('primary_menu_active');
  });

  $('.closeMenu').on('click', function () {
    $('#primary_menu').removeClass('active');
    $('body').removeClass('primary_menu_active');
  });

  $('#primary_navigation  .closeSubMenu').on('click', function () {
    $(this).addClass('hidden');
    $(this).siblings().removeClass('hidden');

    const id = $(this).data('id');
    $('#' + id).removeClass('active');
  });

  $('#primary_navigation  .opemSubMenu').on('click', function () {
    const id = $(this).data('id');
    $('#' + id).addClass('active');
    $(this).addClass('hidden');
    $(this).siblings().removeClass('hidden');
  });

  /* acordion  */

  $('.acordion .btnOpen').on('click', function () {
    $(this).addClass('hidden');
    $(this).siblings().removeClass('hidden');
    $('.acordion-card').removeClass('!hidden');
  });
  $('.acordion .btnClose').on('click', function () {
    $(this).addClass('hidden');
    $(this).siblings().removeClass('hidden');
    $('.acordion-card').addClass('!hidden');
  });

  $('#pagination').on('click', 'a', function (event) {
    event.preventDefault();
    const id = $(this).data('page');
    load_projeto_ajax({ paged: id });
  });

  $('#pagination_consultoria').on('click', 'a', function (event) {
    event.preventDefault();
    const id = $(this).data('page');
    load_consultoria_ajax({ paged: id });
  });

  $('#pagination_noticia').on('click', 'a', function (event) {
    event.preventDefault();
    const id = $(this).data('page');
    load_noticia_ajax({ paged: id });
  });
});

/*
post list 
*/
window.load_projeto_ajax = (datas) => {

  
  if (!datas.paged || datas.paged == '') return;

  const expirationTime = 24 * 60 * 60 * 1000; // 24 horas em milissegundos
  const post_random_ids = getFromCache('post_random_ids', expirationTime);
  if (post_random_ids) datas.ids = post_random_ids;

  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'post',
    data: {
      action: 'load_posts',
      datas: datas,
    },
    success: function (response) {
      if (!post_random_ids && response.ids)
        saveToCache('post_random_ids', response.ids);
      $('#posts-container').html('');
      response.view.forEach(function (post) {
        $('#posts-container').append(post);
      });
      $('#pagination').html(response.pagination);
    },
    error: function (error) {
      console.error('Erro ao carregar posts:', error);
    },
  });
};

// Função para salvar os dados em cache
function saveToCache(key, value) {
  const data = {
    value: value,
    timestamp: new Date().getTime(), // Salva o timestamp atual
  };
  localStorage.setItem(key, JSON.stringify(data));
}

// Função para obter os dados em cache
function getFromCache(key, expirationTime) {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    const currentTime = new Date().getTime();

    // Verifica se os dados ainda são válidos (dentro da validade)
    if (currentTime - data.timestamp < expirationTime) {
      return data.value;
    } else {
      // Dados expirados, remove do cache
      localStorage.removeItem(key);
    }
  }
  return null; // Dados não encontrados ou expirados
}

window.load_consultoria_ajax = (datas) => {
  if (!datas.paged || datas.paged == '') return;

  const expirationTime = 24 * 60 * 60 * 1000; // 24 horas em milissegundos
  const post_random_ids = getFromCache(
    'Random_ids_consultoria',
    expirationTime
  );
  if (post_random_ids) datas.ids = post_random_ids;

  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'post',
    data: {
      action: 'load_consultoria',
      datas: datas,
    },
    success: function (response) {
      if (!post_random_ids && response.ids)
        saveToCache('Random_ids_consultoria', response.ids);
      $('#posts-container').html('');
      response.view.forEach(function (post) {
        $('#posts-container').append(post);
      });
      $('#pagination_consultoria').html(response.pagination);
    },
    error: function (error) {
      console.error('Erro ao carregar posts:', error);
    },
  });
};

window.load_noticia_ajax = (datas) => {
  if (!datas.paged || datas.paged == '') return;

  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'post',
    data: {
      action: 'load_noticia',
      datas: datas,
    },
    success: function (response) {
      $('#posts-container').html('');
      response.view.forEach(function (post) {
        $('#posts-container').append(post);
      });
      $('#pagination_noticia').html(response.pagination);
    },
    error: function (error) {
      console.error('Erro ao carregar posts:', error);
    },
  });
};

/**
 * contact form
 *
 */

document.addEventListener(
  'wpcf7mailfailed',
  function (event) {
    $('.custom-error-message, .custom-error-message-submit').remove();

    $("footer .contact form input[type='submit']").after(
      `<span class="custom-error-message-submit"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.46868 1.83331C4.81001 1.83331 1.83334 4.82398 1.83334 8.49998C1.83334 12.176 4.82401 15.1666 8.50001 15.1666C12.176 15.1666 15.1667 12.176 15.1667 8.49998C15.1667 4.82398 12.162 1.83331 8.46868 1.83331ZM8.50001 13.8333C5.55934 13.8333 3.16668 11.4406 3.16668 8.49998C3.16668 5.55931 5.54468 3.16665 8.46868 3.16665C11.4273 3.16665 13.8333 5.55931 13.8333 8.49998C13.8333 11.4406 11.4407 13.8333 8.50001 13.8333Z" fill="#E31F1F"/>
    <path d="M7.83334 5.16669H9.16668V9.83335H7.83334V5.16669ZM7.83334 10.5H9.16668V11.8334H7.83334V10.5Z" fill="#E31F1F"/>
    </svg>
    ` +
        event.detail.apiResponse.message +
        `</span>`
    );
  },
  false
);

document.addEventListener(
  'wpcf7mailsent',
  function (event) {
    $('.custom-error-message, .custom-error-message-submit').remove();

    $("footer .contact form input[type='submit']").after(
      `<br>  <div class="text-pink mt-4">  ` +
        event.detail.apiResponse.message +
        `</div>`
    );
  },
  false
);

document.addEventListener(
  'wpcf7invalid',
  function (event) {
    $('.custom-error-message, .custom-error-message-submit').remove();

    $("footer .contact form input[type='submit']").after(
      `<span class="custom-error-message-submit"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.46868 1.83331C4.81001 1.83331 1.83334 4.82398 1.83334 8.49998C1.83334 12.176 4.82401 15.1666 8.50001 15.1666C12.176 15.1666 15.1667 12.176 15.1667 8.49998C15.1667 4.82398 12.162 1.83331 8.46868 1.83331ZM8.50001 13.8333C5.55934 13.8333 3.16668 11.4406 3.16668 8.49998C3.16668 5.55931 5.54468 3.16665 8.46868 3.16665C11.4273 3.16665 13.8333 5.55931 13.8333 8.49998C13.8333 11.4406 11.4407 13.8333 8.50001 13.8333Z" fill="#E31F1F"/>
      <path d="M7.83334 5.16669H9.16668V9.83335H7.83334V5.16669ZM7.83334 10.5H9.16668V11.8334H7.83334V10.5Z" fill="#E31F1F"/>
      </svg>
      ` +
        event.detail.apiResponse.message +
        `</span>`
    );

    var inputsWithErrors = event.detail.apiResponse.invalid_fields;

    inputsWithErrors.forEach(function (input) {
      var errorMessage = input.message;
      var inputField = $('[name="' + input.field + '"]')
        .parent()
        .prev();

      // Create a new element to hold the error message
      var errorElement =
        `<span class="custom-error-message inline-block"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path d="M7.96868 1.83331C4.31001 1.83331 1.33334 4.82398 1.33334 8.49998C1.33334 12.176 4.32401 15.1666 8.00001 15.1666C11.676 15.1666 14.6667 12.176 14.6667 8.49998C14.6667 4.82398 11.662 1.83331 7.96868 1.83331ZM8.00001 13.8333C5.05934 13.8333 2.66668 11.4406 2.66668 8.49998C2.66668 5.55931 5.04468 3.16665 7.96868 3.16665C10.9273 3.16665 13.3333 5.55931 13.3333 8.49998C13.3333 11.4406 10.9407 13.8333 8.00001 13.8333Z" fill="#E31F1F"/>
    <path d="M7.33334 5.16669H8.66668V9.83335H7.33334V5.16669ZM7.33334 10.5H8.66668V11.8334H7.33334V10.5Z" fill="#E31F1F"/>
  </svg> ` +
        errorMessage +
        `</span>`;

      // Insert the error element before the input field
      inputField.before(errorElement);
    });
  },
  false
);
